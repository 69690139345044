import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import FlashOn from "@material-ui/icons/FlashOn";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ListItemText from "@material-ui/core/ListItemText";

import Starter from "../images/v2/Pricing/Starter.svg";
import Business from "../images/v2/Pricing/Business.svg";
import background from "../images/v2/sales-pipeline/background.svg";
import customizePipelineIcon from "../images/v2/sales-pipeline/customize-pipeline-icon.svg";
import multiplePipeline from "../images/v2/sales-pipeline/multiplePipeline.svg";
import projectionsIcon from "../images/v2/sales-pipeline/projections-icon.svg";
import customerRelationshipImg from "../images/v2/sales-pipeline/customer-relationship.svg";
import rightTimeDealImg from "../images/v2/sales-pipeline/right-time-deal.svg";
import accessibleDataImg from "../images/v2/sales-pipeline/accessible-data.svg";
import teamManagementImg from "../images/v2/sales-pipeline/team-management.svg";
import revenueGenerationImg from "../images/v2/sales-pipeline/revenue-generation.svg";
import focusImg from "../images/v2/sales-pipeline/Focus on the Rightareas.svg";
import pricingImg from "../images/v2/sales-pipeline/pricing.svg";
import lightBlueBgImg from "../images/v2/light-blue-bg.svg";
import testimonialBgImg from "../images/v2/testimonial-bg.svg";
import requestDemoBGImg from "../images/v2/request-demo-bg.svg";

import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import WhiteButton from "../components/v2/WhightButton";
import SEO from "../components/SEO";

import useWidth from "../hooks/useWidth";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";

const pricingCardDetails = [
  {
    title: "Starter",
    user: "5 users",
    monthly: "4",
    annually: "10",
    description: "For small team that need \n" + "powerful tool.",
    feature: ["Opportunity Management", "Custom Fields", "Data Import"],
    buttonVariant: "outlined",
    logo: Starter,
    alt: "crm price 1",
  },
  {
    title: "Business",
    user: "10-12 users",
    description: "For medium level companies that\n" + "need many features.",
    subheader: "Most popular",
    monthly: "7",
    annually: "15",
    feature: ["Email Integration", "Browser Extension", "Lead Management"],
    buttonVariant: "contained",
    logo: Business,
    alt: "crm price 2",
  },
];

const client = {
  image: (
    <StaticImage
      alt="Pepper Cloud + Cuscapi Singapore Pte. Ltd. "
      layout="constrained"
      src="../images/v2/testimonials-images/anthony-gerald-peppercloud-customer.png"
      width="500"
    />
  ),
  name: "Anthony Gerald",
  position: "Group CEO",
  company: "Cuscapi Berhad, Malaysia",
  content: (
    <React.Fragment>
      We chose Pepper Cloud after due consideration and now it is paying off
      well!
      <br />
      <br />
      The CRM has a strong sales pipeline management tool that helps us to
      manage our business operations in 6 countries. It has a robust user
      management system with clearly defined access levels for data security and
      protection, which we needed.
      <br />
      <br />
      What I personally like the most in Pepper Cloud CRM is their service
      dedication and personal touch. There is a face behind the product to whom
      we can reach out to whenever there is a problem.
    </React.Fragment>
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    // [theme.breakpoints.down("sm")]: {
    background: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // },
  },
  bgImage: {
    position: "absolute",
    zIndex: -1,
    height: "auto",
    width: "100%",
  },
  img: {
    height: "auto",
    maxWidth: "100%",
  },
  testimonialCustomerImg: {
    maxWidth: "90%",
    width: "290px",
    borderRadius: "10px",
    boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
    marginTop: "2.65rem",
    marginLeft: "3rem",
  },
  testimonialBg: {
    background: `url(${testimonialBgImg}) no-repeat`,
    backgroundSize: "195px 223px",
  },
  bookNowButton: {
    // width: 307,
    // fontSize: 24,
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  lightBlueBg: {
    background: `url(${lightBlueBgImg}) center bottom`,
    backgroundSize: "cover",
    minHeight: "900px",
  },
  media: {
    width: 87.6,
    height: 115,
    margin: 21,
  },
  businessMedia: {
    width: 87.6,
    height: 115,
    margin: "21px 0px 40px 21px",
  },
  priceCard: {
    // minWidth: 382,
    maxWidth: 380,
    padding: 15,
    boxShadow: "10px 20px 50px 0 rgba(2, 145, 174, 0.16)",
    border: "solid 1px #e2f0ff",
  },
  priceCard0: {
    position: "absolute",
    zIndex: 0,
    transform: "scale(0.9)",
  },
  priceCard1: {
    marginLeft: 134,
    zIndex: 2,
    position: "inherit",
    marginBottom: 60,
  },
  FeatureIcon: {
    color: "#009f53",
    minWidth: 38,
  },
  featureHeader: {
    color: "#009f53",
  },
  recomandIcon: {
    color: "#ffb400",
  },
  featureListItem: {
    padding: 0,
  },
  pricingImg: {
    background: `url(${pricingImg})`,
    backgroundSize: "cover",
    // backgroundPosition: "center",
  },
  changePipelineBg: {
    backgroundImage:
      "linear-gradient(to top, #e7f2fe, rgba(231, 242, 254, 0.57) 90%, rgba(231, 242, 254, 0))",
  },
  accordionTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  imageBigscreen: {
    [theme.breakpoints.up("xl")]: {
      textAlign: "left",
    },
  },
}));

const SalesPipeline = (props) => {
  const width = useWidth();
  const classes = useStyles();

  const [activePipeline, setActivePipeline] = React.useState(0);
  const [activeLimitations, setActiveLimitations] = React.useState([
    true,
    true,
    true,
  ]);
  const [activeFocusAreas, setActiveFocusAreas] = React.useState(0);
  const [activeSalesProjections, setActiveSalesProjections] = React.useState(0);

  const pipeline = React.useMemo(() => {
    const {
      data: { customizablePipelines, customizableStages, customizableFields },
    } = props;
    const pipelines = [
      {
        title: "Customisable pipelines",
        description: (
          <>
            Your business processes and sales management are unique and the CRM
            solution should be able to adapt to your custom requirements. With
            Pepper Cloud CRM, you can tailor your pipeline to maintain
            hassle-free sales funnel for leads and opportunities.
          </>
        ),
        img: getImage(customizablePipelines),
        alt: "3 stage pipeline",
      },
      {
        title: "Customisable stages",
        description:
          "In small businesses, a typical sales pipeline has 4-6 stages. If your business requires more stages, then, you can add them with a click of a button. With an easy drag-and-drop interface, you can also rearrange the sales stages at any point in time.",
        img: getImage(customizableStages),
        alt: "sales pipeline templates",
      },
      {
        title: "Customisable fields",
        description:
          "Experience the power to create unlimited custom fields and collect all the necessary information to organise your data. Configure the fields as per your requisites of the sales process and switch them on or off whenever you want.",
        img: getImage(customizableFields),
        alt: "Custom form fields",
      },
    ];

    return pipelines;
  }, []);

  const limitations = [
    {
      title: "Multiple sales pipelines",
      description:
        "Manage your different business verticals individually, yet together. With an option to build multiple pipelines in your CRM software, you can have a dedicated pipeline for each of your business verticals, products, customer types, or locations.",
    },
    {
      title: "Visual pipelines",
      description:
        "Gain a quick glimpse of your pipelines with various view options - List view, Kanban view, and Forecast view. The visual representation of your processes ensures that you have the visibility of your opportunities at all stages. Drill down into each opportunity to monitor the health of your business.",
    },
    {
      title: "Default sales pipeline",
      description:
        "Choose your default pipeline with ease and save yourself from manually choosing it every time a new record is created. Use the readily available, four-stage pipeline as a default one or build your own as per your business requirements and set it as the default pipeline.",
    },
  ];

  const focusAreas = React.useMemo(() => {
    const {
      data: { leadsAndOpportunities, salesPipelineHealth, salesActivities },
    } = props;
    const focusAreas = [
      {
        title: "Lead and opportunities",
        description:
          "With easy-to-use sales CRM, smartly identify the prospects and skillfully track the journey of your hot leads from qualification to closing deals. Follow up and prioritise your leads and opportunities. ",
        img: getImage(leadsAndOpportunities),
        alt: "Leads and opportunities",
      },
      {
        title: "Sales pipeline health",
        description:
          "Dig out the leads and opportunities that have been stagnant for an extended period and refocus the efforts of your sales team in the right direction. Assign the age limit to your deals and rally up your team around the deals before they are lost to your competitors.",
        img: getImage(salesPipelineHealth),
        alt: "sales pipeline health",
      },
      {
        title: "Sales activities",
        description:
          "Pepper Cloud CRM tool empowers your team by notifying them when the actions are needed. With our hassle-free interface and easily accessible action tools, your team assuredly follows the actionable tasks and track them effortlessly.",
        img: getImage(salesActivities),
        alt: "sales activities",
      },
    ];

    return focusAreas;
  }, []);

  const salesProjections = React.useMemo(() => {
    const {
      data: { salesForecasting, businessOpportunities, salesDeals },
    } = props;
    const salesProjections = [
      {
        title: "Stagewise Vs Global sales forecasting",
        description:
          "Stay on top of the deals and predict your most accurate monthly or quarterly sales revenue with the help of winning probability on all opportunities. Assign winning probability to every deal or to every stage.",
        img: getImage(salesForecasting),
        alt: "sales forecasting",
      },
      {
        title: "Leads to business opportunities",
        description:
          "Estimate the leads who are likely to be your customer through a weighted average. With intuitive CRM, get the projections of opportunities that are likely to win.",
        img: getImage(businessOpportunities),
        alt: "sales opportunity stages",
      },
      {
        title: "Closing of sales deals",
        description:
          "Slide your opportunities to “won” or “lost” from any stage of your pipeline and close the deals with ease.",
        img: getImage(salesDeals),
        alt: "Sales deals stages",
      },
    ];

    return salesProjections;
  }, []);

  const productBenefits = [
    {
      text: (
        <React.Fragment>
          Enhance your customer relationships through personalised interactions
          and engagements.
        </React.Fragment>
      ),
      alt: "Customer relationship management",
      img: customerRelationshipImg,
    },
    {
      text: (
        <React.Fragment>
          Gain total visibility of your sales funnel and focus on the right
          deals at the right time.
        </React.Fragment>
      ),
      alt: "Real-time sales data",
      img: rightTimeDealImg,
    },
    {
      text: (
        <React.Fragment>
          Boost the productivity of your sales team with easily accessible data,
          on the go.
        </React.Fragment>
      ),
      alt: "Sales pipeline health analysis",
      img: accessibleDataImg,
    },
    {
      text: (
        <React.Fragment>
          Improve team management and coordination by sharing key information
          about each deal.
        </React.Fragment>
      ),
      alt: "Sales enablement team",
      img: teamManagementImg,
    },
    {
      text: (
        <React.Fragment>
          Predict your financial health and project your future revenue with the
          help of the{" "}
          <a href="https://peppercloud.com/sales-dashboard">
            intuitive sales dashboard
          </a>
          .
        </React.Fragment>
      ),
      alt: "Sales forecasting + Sales growth",
      img: revenueGenerationImg,
    },
  ];

  return (
    <React.Fragment>
      <SEO
        description="Customise, optimise, and streamline your sales process with efficient sales pipeline management tool. Track your prospects and leads conveniently and have conversations that converts."
        keywords="sales pipeline management tool,Sales pipeline,sales pipeline management,Sales Pipeline,sales pipeline management software,Sales CRM feature"
        ogData={{
          "og:image": [
            "/meta/og/1X1/sales-pipeline.png",
            "/meta/og/1024X512/sales-pipeline.png",
            "/meta/og/1200X630/sales-pipeline.png",
          ],
        }}
        pathname="/sales-pipeline-management-tool"
        title="Best Sales Pipeline Management Tool | Pepper Cloud CRM"
      />
      <div className={classes.root}>
        <Container>
          <Box display="flex" justifyContent="center">
            <Box maxWidth={800} py={4} textAlign="center">
              <HeaderTypography
                color="common.white"
                component="h1"
                mt={{ md: 6 }}
              >
                Sales pipeline management
              </HeaderTypography>
              <ParagraphTypography color="common.white" fontSize={18}>
                With your favourite sales CRM, track your leads conveniently and
                effortlessly transform leads into winning opportunities.
                Customise, optimise, and streamline your{" "}
                <a href="https://blog.peppercloud.com/sales-activities-building-blocks-of-sales-process/">
                  sales activities
                </a>{" "}
                and let your team work laser-focused.
              </ParagraphTypography>
              <Box mb={4} />
              <WhiteButton
                color="primary"
                component={Link}
                size="large"
                to="/contact"
                variant="contained"
              >
                Book a demo
              </WhiteButton>
            </Box>
            <Box />
          </Box>
          <Box textAlign="center">
            <StaticImage
              alt="Sales pipeline template that helps in tracking the sales process"
              className={classes.img}
              src="../images/v2/sales-pipeline/kanban@2x.png"
            />
          </Box>
        </Container>
      </div>

      <Box mb={8} mt={5}>
        <Divider light />
      </Box>

      <Container>
        <Box display="flex" justifyContent="center">
          <Box maxWidth={970} pb={3} textAlign="center">
            <Box display="flex" justifyContent="center">
              <HeaderTypography component="h2" maxWidth={720} mb={5} mt={0}>
                Visualise your sales pipeline, witness your growth
              </HeaderTypography>
            </Box>
            <ParagraphTypography component="h2" fontSize={18} pb={2}>
              Pepper Cloud CRM is built to assist you with end-to-end sales
              pipeline management. It facilitates you to organise, track, and
              streamline your process; design your sales strategy; generate
              leads and convert them into opportunities quickly and
              conveniently. The coherent and sensible visuals of your processes
              empower you to get accurate revenue forecasts.
            </ParagraphTypography>
            <Box mb={4} />
            <Button
              className={classes.bookNowButton}
              color="secondary"
              component={Link}
              size="large"
              to="/contact"
              variant="contained"
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Container>

      <Box mb={10} mt={{ md: 8 }}>
        <Divider light />
      </Box>

      <Box component={Container} textAlign="center">
        <Box display="flex" justifyContent="center">
          <HeaderTypography
            component="h2"
            lineHeight={1.15}
            maxWidth={720}
            mb={{ sm: 0, xs: 0, md: 6 }}
            mt={0}
          >
            Manage your sales process effortlessly
          </HeaderTypography>
        </Box>
      </Box>

      <Grid alignItems="center" container justify="center">
        <Grid item md={6} sm={12}>
          <Box display="flex" justifyContent={{ sm: "center", md: "flex-end" }}>
            <Box maxWidth={620} mt={4} p={1}>
              <HeaderTypography
                alignItems="center"
                component="h3"
                display="flex"
                fontSize="1.75rem"
                fontWeight={700}
                lineHeight={1.4}
                mb={2}
                justifyContent={{ sm: "center" }}
                className={classes.accordionTitle}
              >
                <Box
                  alt="Sales CRM feature"
                  component="img"
                  height={{ sm: 40, xs: 40 }[width] || 89}
                  mr={{ sm: 2, xs: 2 }[width] || 3}
                  src={customizePipelineIcon}
                />
                Build the pipeline for your needs
              </HeaderTypography>
              <ParagraphTypography
                component="h3"
                mt={0}
                textAlign={{ sm: "center", xs: "center" }[width]}
              >
                With the{" "}
                <a href="https://peppercloud.com/">best sales CRM software</a>{" "}
                for small businesses and medium enterprises, customise your
                sales pipeline to match with your unique business needs and
                start sliding the opportunities to “won” in no time.
              </ParagraphTypography>
              <Box my={3}>
                <Divider light />
              </Box>
              {pipeline.map((each, index) => (
                <div key={each.title}>
                  <Box my={1} p={1}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      onClick={() =>
                        setActivePipeline(index === activePipeline ? -1 : index)
                      }
                    >
                      <Box
                        color="text.secondary"
                        component="h4"
                        fontSize="h6.fontSize"
                        fontWeight="600"
                        m={0}
                      >
                        {each.title}
                      </Box>
                      <IconButton
                        aria-label="Expand or Collapse"
                        className={classes.expendButton}
                        color="default"
                      >
                        {index !== activePipeline ? (
                          <AddIcon fontSize="small" />
                        ) : (
                          <RemoveIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Box>
                    <Collapse in={activePipeline === index}>
                      <ParagraphTypography fontSize={16} mr={6} mt={2}>
                        {each.description}
                      </ParagraphTypography>
                    </Collapse>
                  </Box>
                  <Divider light />
                </div>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box className={classes.imageBigscreen} textAlign="right">
            <GatsbyImage
              alt={
                activePipeline !== -1
                  ? pipeline[activePipeline].alt
                  : pipeline[0].alt
              }
              image={
                activePipeline !== -1
                  ? pipeline[activePipeline].img
                  : pipeline[0].img
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Box mb={6} mt={{ xs: 0, sm: 0, md: 12 }}>
        <Divider light />
      </Box>

      <Box component={Container} textAlign="center">
        <Box pb={{ sm: 3, xs: 3 }[width] || 8} pt={0}>
          <HeaderTypography
            alignItems="center"
            component="h3"
            display="flex"
            fontSize="1.75rem"
            fontWeight={700}
            justifyContent="center"
            lineHeight={1.4}
            mb={2}
            className={classes.accordionTitle}
          >
            <Box
              alt="Customisable pipeline"
              component="img"
              height={{ sm: 40, xs: 40 }[width] || 89}
              mr={{ sm: 2, xs: 2 }[width] || 3}
              src={multiplePipeline}
            />
            Break free from the limitations
          </HeaderTypography>
          <Box display="flex" justifyContent="center">
            <ParagraphTypography maxWidth={850} mb={{ xs: 0, sm: 0 }}>
              Open up to a multitude of business opportunities with effortless
              sales pipeline management.
              <a
                href={
                  "https://blog.peppercloud.com/an-ultimate-guide-to-sales-pipeline/"
                }
              >
                {" "}
                Learn how to create sales pipelines{" "}
              </a>{" "}
              for your business needs.
            </ParagraphTypography>
          </Box>
        </Box>

        <Grid container spacing={4}>
          {limitations.map((each, index) => (
            <Grid item key={each.title} md={4} sm={12}>
              <Divider light />
              <Box
                my={{ sm: 0, xs: 0, md: 1 }}
                px={2}
                pt={2}
                pb={{ xs: 0, sm: 0, md: 2 }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  onClick={() => {
                    activeLimitations[index] = !activeLimitations[index];
                    setActiveLimitations([...activeLimitations]);
                  }}
                >
                  <Box
                    color="text.secondary"
                    component="h4"
                    fontSize="h6.fontSize"
                    fontWeight="600"
                    m={0}
                  >
                    {each.title}
                  </Box>
                  <IconButton
                    aria-label="Expand or Collapse"
                    className={classes.expendButton}
                    color="default"
                  >
                    {!activeLimitations[index] ? (
                      <AddIcon fontSize="small" />
                    ) : (
                      <RemoveIcon fontSize="small" />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={activeLimitations[index]}>
                  <ParagraphTypography
                    fontSize={16}
                    minHeight={{ md: 120 }}
                    mb={{ sm: 0, xs: 0 }}
                    mr={6}
                    mt={2}
                    textAlign="initial"
                  >
                    {each.description}
                  </ParagraphTypography>
                </Collapse>
              </Box>
              <Hidden smDown>
                <Divider light />
              </Hidden>
            </Grid>
          ))}
        </Grid>
        <br />
        <br />
        <br />
        <Button
          className={classes.bookNowButton}
          color="secondary"
          component={Link}
          size="large"
          to="/contact"
          variant="contained"
        >
          Try these features
        </Button>
        <br />
        <br />
      </Box>

      <div className={classes.changePipelineBg}>
        <Container>
          <StaticImage
            alt="Multiple sales pipeline"
            layout="fullWidth"
            src="../images/v2/sales-pipeline/multiple-sales-pipelines@2x.png"
            width="100%"
          />
        </Container>
      </div>
      <Box mt={{ md: 8 }}>
        <Grid
          alignItems="center"
          container
          direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
          justify="center"
        >
          <Grid item md={5} sm={12}>
            <GatsbyImage
              alt={
                activeFocusAreas !== -1
                  ? focusAreas[activeFocusAreas].alt
                  : focusAreas[0].alt
              }
              image={
                activeFocusAreas !== -1
                  ? focusAreas[activeFocusAreas].img
                  : focusAreas[0].img
              }
            />
          </Grid>
          <Grid item md={1} sm={12} />
          <Grid item md={6} sm={12}>
            <Box display="flex">
              <Box maxWidth={620} p={1}>
                <HeaderTypography
                  alignItems="center"
                  component="h3"
                  display="flex"
                  fontSize="1.75rem"
                  fontWeight={700}
                  lineHeight={1.4}
                  mb={2}
                  className={classes.accordionTitle}
                >
                  <Box
                    alt="Sales CRM feature"
                    component="img"
                    height={{ sm: 40, xs: 40 }[width] || 89}
                    mr={{ sm: 2, xs: 2 }[width] || 3}
                    src={focusImg}
                  />
                  Bring clarity and focus into your sales pipeline
                </HeaderTypography>
                <ParagraphTypography
                  fontSize={18}
                  textAlign={{ sm: "center", xs: "center" }[width]}
                >
                  Take the guesswork out of your{" "}
                  <a
                    href={
                      "https://blog.peppercloud.com/sales-activities-building-blocks-of-sales-process/"
                    }
                  >
                    sales activities
                  </a>
                  . Follow the clutter-free visuals of your sales process and
                  understand the health of your deals instantly. Refocus your
                  team efforts for better productivity
                </ParagraphTypography>
                <Box mb={2} mt={3}>
                  <Divider light />
                </Box>
                {focusAreas.map((each, index) => (
                  <div key={each.title}>
                    <Box my={1} p={2}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        onClick={() =>
                          setActiveFocusAreas(
                            index === activeFocusAreas ? -1 : index
                          )
                        }
                      >
                        <Box
                          component="h4"
                          fontSize="h6.fontSize"
                          fontWeight="600"
                          m={0}
                        >
                          {each.title}
                        </Box>
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {index !== activeFocusAreas ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={activeFocusAreas === index}>
                        <ParagraphTypography fontSize={16} mr={6} mt={2}>
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Divider light />
                  </div>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Hidden smDown>
        <Box my={10}>
          <Divider light />
        </Box>
      </Hidden>

      <Box className={classes.lightBlueBg}>
        <Box component={Container} textAlign="center">
          <Box>
            <HeaderTypography
              alignItems="center"
              component="h2"
              display="flex"
              fontSize="1.75rem"
              fontWeight={700}
              justifyContent="center"
              lineHeight={1.4}
              mb={3}
              className={classes.accordionTitle}
            >
              <Box
                alt="Sales projections"
                component="img"
                height={{ sm: 40, xs: 40 }[width] || 89}
                mr={{ sm: 2, xs: 2 }[width] || 3}
                src={projectionsIcon}
              />
              Get your realistic sales projections
            </HeaderTypography>
            <Box display="flex" justifyContent="center">
              <ParagraphTypography maxWidth={850}>
                Not all opportunities have the same chances of winning and
                treating every opportunity as the same would give unrealistic
                projections. Pepper Cloud CRM allows you to assign winning
                probability as per the sales stage or opportunity type.
              </ParagraphTypography>
            </Box>
          </Box>
        </Box>
        <br />
        <br />
        <Grid container spacing={10}>
          <Grid item md={6} sm={12}>
            <Box
              display="flex"
              justifyContent={{ sm: "center", md: "flex-end" }}
            >
              <Box maxWidth={620} mt={{ md: 4 }} p={1}>
                <Box mb={2} mt={4}>
                  <Divider light />
                </Box>
                {salesProjections.map((each, index) => (
                  <div key={each.title}>
                    <Box my={1} p={2}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        onClick={() =>
                          setActiveSalesProjections(
                            index === activeSalesProjections ? -1 : index
                          )
                        }
                      >
                        <Box
                          component="h3"
                          fontSize="h6.fontSize"
                          fontWeight="600"
                          m={0}
                        >
                          {each.title}
                        </Box>
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {index !== activeSalesProjections ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={activeSalesProjections === index}>
                        <ParagraphTypography fontSize={16} mr={6} mt={2}>
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Divider light />
                  </div>
                ))}
                <br />
                <br />
                <br />
                <Box textAlign={{ xs: "center", sm: "center" }[width]}>
                  <Button
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                  >
                    Build my sales pipeline
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box px={6} className={classes.imageBigscreen} textAlign="right">
              <GatsbyImage
                alt={
                  activeSalesProjections !== -1
                    ? salesProjections[activeSalesProjections].alt
                    : salesProjections[0].alt
                }
                image={
                  activeSalesProjections !== -1
                    ? salesProjections[activeSalesProjections].img
                    : salesProjections[0].img
                }
              />
            </Box>
          </Grid>
        </Grid>
        <br />
        <br />
      </Box>

      <Box component={Container} textAlign="center">
        <Box pb={8} pt={7}>
          <HeaderTypography
            component="h2"
            display="flex"
            fontSize="1.75rem"
            fontWeight={700}
            justifyContent="center"
            lineHeight={1.4}
            mb={3}
          >
            Win deals faster with effective pipeline management
          </HeaderTypography>
        </Box>
        <Box m={1}>
          <Grid container justify="center" spacing={6}>
            {productBenefits.map((each) => (
              <Grid item key={each.text} md={4} sm={6} xs={12}>
                <Box
                  alt={each.alt}
                  component="img"
                  height={72}
                  mb={2}
                  src={each.img}
                />
                <ParagraphTypography
                  color="text.primary"
                  fontWeight={600}
                  mb={8}
                >
                  {each.text}
                </ParagraphTypography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        my={{ sm: 4, xs: 2 }[width] || 8}
      >
        <Button
          className={classes.bookNowButton}
          color="secondary"
          component={Link}
          size="large"
          to="/contact"
          variant="contained"
        >
          Get Started
        </Button>
      </Box>

      <div className={classes.pricingImg}>
        <Container>
          {/*<img alt="PepperCloud: Pricing" className={classes.img} src={pricingImg} width="100%" />*/}
          <Grid alignItems="center" container justify="center">
            <Grid item md={6} sm={12}>
              <Box
                pb={2}
                pl={{ md: 12 }}
                pt={14}
                textAlign={{ sm: "center", xs: "center" }[width]}
              >
                <HeaderTypography
                  color="common.white"
                  component="h2"
                  fontSize={40}
                  mb={2}
                  mt={{ md: 10 }}
                >
                  Our pricing plans
                </HeaderTypography>
                <ParagraphTypography
                  component="h3"
                  color="common.white"
                  fontSize={18}
                  fontWeight={400}
                  pr={{ md: 8 }}
                >
                  Strategise your sales plan and build your pipelines from an{" "}
                  <a href="https://peppercloud.com/integration">
                    integrated CRM platform
                  </a>
                  .
                </ParagraphTypography>

                <Hidden smDown>
                  <Box display="flex" flexWrap="wrap" mt={6}>
                    {/*<Box m={1}>*/}
                    {/*	<WhiteButton color="primary" component={Link} href="/pricing" size="large" variant="contained">*/}
                    {/*		See Our Plans*/}
                    {/*	</WhiteButton>*/}
                    {/*</Box>*/}
                    <Box>
                      <WhiteButton
                        color="primary"
                        component={Link}
                        size="large"
                        to="/contact"
                        variant="contained"
                      >
                        Request for demo
                      </WhiteButton>
                    </Box>
                  </Box>
                </Hidden>
                <Hidden mdUp>
                  <Grid
                    alignItems="center"
                    container
                    justify="center"
                    spacing={2}
                  >
                    <Grid
                      component={Box}
                      item
                      md={6}
                      sm={6}
                      textAlign="center"
                      xs={12}
                    >
                      <WhiteButton
                        color="primary"
                        component={Link}
                        href="/crm-platfrom-pricing"
                        size="large"
                        variant="contained"
                      >
                        See our plans
                      </WhiteButton>
                    </Grid>
                  </Grid>
                </Hidden>
              </Box>
            </Grid>
            <Grid item md={6} sm={12}>
              <Hidden implementation="css" smDown>
                <Box position="relative">
                  {pricingCardDetails.map((tier, index) => (
                    <Card
                      className={
                        classes.priceCard + " " + classes[`priceCard${index}`]
                      }
                      key={tier.title}
                    >
                      <CardMedia
                        className={
                          tier.title === "Business"
                            ? classes.businessMedia
                            : classes.media
                        }
                        image={tier.logo}
                        title={tier.alt}
                      />
                      {tier.title === "Business" && (
                        <Box
                          alignItems="center"
                          display="flex"
                          padding="0px 0px 0px 15px"
                        >
                          <FlashOn className={classes.recomandIcon} />
                          <Typography color="textSecondary" variant="body2">
                            RECOMMENDED
                          </Typography>
                        </Box>
                      )}
                      <Box padding={"0px 61px 0px 18px"}>
                        <Box fontSize="28px" fontWeight="600">
                          {tier.title}
                        </Box>
                        <Box color="#516f90" fontSize="20px" fontWeight="600">
                          {tier.user}
                        </Box>
                        <ParagraphTypography>
                          {tier.description}
                        </ParagraphTypography>
                      </Box>
                      <CardContent>
                        <ParagraphTypography className={classes.featureHeader}>
                          Feature included
                        </ParagraphTypography>
                        <List>
                          {tier.feature.map((line) => (
                            <ListItem
                              classes={{
                                root: classes.featureListItem, // class name, e.g. `classes-nesting-root-x`
                              }}
                              key={line}
                            >
                              <ListItemIcon className={classes.FeatureIcon}>
                                <CheckCircle />
                              </ListItemIcon>
                              <ListItemText primary={line} />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Box component={Container} mb={4} textAlign="center">
        <Box py={8}>
          <HeaderTypography
            alignItems="center"
            component="h2"
            display="flex"
            fontSize={40}
            justifyContent="center"
            lineHeight={1.2}
            mb={2}
            mt={0}
          >
            Our happy customers are accelerating their sales growth
          </HeaderTypography>
          <Box display="flex" justifyContent="center">
            <ParagraphTypography maxWidth={720}>
              Many businesses trust Pepper Cloud CRM software to run their
              operations globally. Know about our clients&apos; experience in
              their words.
            </ParagraphTypography>
          </Box>
        </Box>
        <Container maxWidth="lg">
          <SingleTestimonial {...client} />
        </Container>
      </Box>

      <Box
        className={classes.requestDemoRoot}
        py={{ sm: 8, xs: 4 }[width] || 12}
        textAlign="center"
      >
        <Box alignItems="center" display="flex" justifyContent="center">
          <HeaderTypography
            color="common.white"
            component="h2"
            fontSize={40}
            my={0}
            fontWeight={600}
            textAlign="center"
          >
            Ready to build your business better and sell faster?
          </HeaderTypography>
        </Box>
        <Box mt={{ xs: 3, sm: 3, md: 6 }} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            href="/crm-platfrom-pricing"
            size="large"
            variant="contained"
          >
            See our plans
          </WhiteButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export const query = graphql`
  query {
    salesDeals: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "sales-deals@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    salesForecasting: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "sales-forecasting@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    businessOpportunities: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "business-opportunities@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    leadsAndOpportunities: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "lead-and-opportunities@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    salesPipelineHealth: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "sales-pipeline-health@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    salesActivities: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "sales-activities@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    customizablePipelines: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "customisable-pipelines@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    customizableStages: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "customisable-stages@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    customizableFields: file(
      sourceInstanceName: { eq: "sales-dynamic" }
      name: { eq: "customisable-fields@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
  }
`;

SalesPipeline.propTypes = {
  data: PropTypes.object,
};

export default SalesPipeline;
